<template>
  <v-container class="full-height pa-4" fluid>
    <div
      v-if="!inventoryItemPurchase.id"
      class="d-flex align-center justify-center full-width full-height"
    >
      <v-progress-circular v-if="isDataLoading" size="96" width="8" color="primary" indeterminate />
      <h1 v-else class="display-1 grey--text">
        {{ $t('failed_to_load_inventory_item_purchase') }}.
      </h1>
    </div>

    <template v-else>
      <v-row>
        <v-col cols="12">
          <h1 class="display-1 grey--text">{{ $t('inventory_item_purchase_details') }}</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-preserve-whitespace">
          {{ inventoryItemPurchaseJson }}
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import inventoryItemPurchaseService from '@/api/inventory-item-purchase-service';

export default {
  name: 'InventoryItemPurchase',

  mixins: [crudMixin],

  data() {
    return {
      inventoryItemPurchase: {},
      isDataLoading: true,
    };
  },

  computed: {
    inventoryItemPurchaseJson() {
      return JSON.stringify(this.inventoryItemPurchase, null, 2);
    },
  },

  created() {
    this.crudMixin_getById(
      inventoryItemPurchaseService.getById,
      'inventoryItemPurchase',
      this.$route.params.id,
      {},
      false,
    );
  },
};
</script>

<style scoped></style>
